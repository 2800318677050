import { PMChartVersionTypeEnum, PMChartBpcStatusEnum, PMChartStatusEnum } from '@/ship/Enums/ProjectManagementEnums';
import IDictionary from '@/ship/Models/IDictionary';
import { IPMChart, IPMChartBpc, IPMChartVersion, IPMCity } from '@/ship/Models/IProjectManagement';

export const defaultBpc: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            startDate: '2024-01-11',
            endDate: '2024-02-11',
            childTasks: {
                data: [
                    {
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    startDate: '2024-03-13',
                                    endDate: '2024-03-13',
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {},
                    },
                    {
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    startDate: '2024-03-13',
                                    endDate: '2024-03-13',
                                    childTasks: {
                                        data: [
                                            {
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                startDate: '2024-03-13',
                                                endDate: '2024-03-13',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const defaultDpcExecTasks: IDictionary = {
    data: {
        data: {
            status: PMChartBpcStatusEnum.prepared,
            tasks: {
                data: [
                    {
                        id: 1,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3',
                        completenessPlan: 100.0,
                        startDatePlan: '2024-01-11',
                        endDatePlan: '2024-02-11',
                        completenessFact: 100.0,
                        startDateFact: '2024-01-11',
                        endDateFact: '2024-02-11',
                        completenessGeneral: 100.0,
                        description: '',
                        childTasks: {
                            data: [
                                {
                                    id: 2,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {
                                        data: [
                                            {
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.1.2',
                                                completenessPlan: 100.0,
                                                startDatePlan: '2024-01-11',
                                                endDatePlan: '2024-02-11',
                                                completenessFact: 100.0,
                                                startDateFact: '2024-01-11',
                                                endDateFact: '2024-02-11',
                                                completenessGeneral: 100.0,
                                                description: '',
                                                childTasks: [],
                                            },
                                        ],
                                    },
                                },
                                {
                                    id: 3,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.2',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {},
                                },
                                {
                                    id: 4,
                                    name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                                    code: '3.3',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {
                                        data: [
                                            {
                                                id: 5,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                completenessPlan: 100.0,
                                                startDatePlan: '2024-01-11',
                                                endDatePlan: '2024-02-11',
                                                completenessFact: 100.0,
                                                startDateFact: '2024-01-11',
                                                endDateFact: '2024-02-11',
                                                completenessGeneral: 100.0,
                                                description: '',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 6,
                                                            name: 'Выполнение основных инженерных изысканий',
                                                            code: '3.3.1',
                                                            completenessPlan: 100.0,
                                                            startDatePlan: '2024-01-11',
                                                            endDatePlan: '2024-02-11',
                                                            completenessFact: 100.0,
                                                            startDateFact: '2024-01-11',
                                                            endDateFact: '2024-02-11',
                                                            completenessGeneral: 100.0,
                                                            description: '',
                                                            childTasks: {
                                                                data: [
                                                                    {
                                                                        id: 7,
                                                                        name: 'Кладка наружных и внутренних стен, перегородок',
                                                                        code: '3.3.1.1',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                    {
                                                                        id: 8,
                                                                        name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                                        code: '3.3.1.2',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                    {
                                                                        id: 9,
                                                                        name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                                        code: '3.3.1.3',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    },
};
export const defaultWeeklyReportJobs: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            id: 1,
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            number: 1,
            percentReadyPlan: 100,
            percentReadyFact: 88.5,
            addedPhotos: 0,
            description:
                'Устройство внутренних перегородок и балконов. Устройство внутренних перегородок и балконов. Устройство внутренних перегородок и балконов. Столбец описание работ растягивается на всю ширину экрана (Fill). Максимально в ячейку влазит 2 строки во всю ширину. Всё, что не влезет, уходит в “...” (3 точки)',
            childTasks: {
                data: [
                    {
                        id: 2,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        number: 2,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    number: 4,
                                    percentReadyPlan: 100,
                                    percentReadyFact: 90.5,
                                    addedPhotos: 2,
                                    description: 'Устройство внутренних перегородок и балконов',
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        id: 3,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        number: 5,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {},
                    },
                    {
                        id: 4,
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        number: 6,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {
                            data: [
                                {
                                    id: 5,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    number: 7,
                                    percentReadyPlan: 100,
                                    percentReadyFact: 90.5,
                                    addedPhotos: 1,
                                    description: 'Устройство внутренних перегородок и балконов',
                                    childTasks: {
                                        data: [
                                            {
                                                id: 6,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                number: 8,
                                                percentReadyPlan: 100,
                                                percentReadyFact: 90.5,
                                                addedPhotos: 1,
                                                description: 'Устройство внутренних перегородок и балконов',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 7,
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            number: 9,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 8,
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            number: 11,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 9,
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            number: 12,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

export const defaultReportDeadlines: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            id: 1,
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            durationGeneral: '356 к. дн.',
            startDate: '2023-08-21',
            endDate: '2024-08-21',
            startDateBaseSchedule: '2023-08-21',
            endDateBaseSchedule: '2023-08-21',
            durationWorkingDays: '356 р. дн.',
            durationPredict: '356 р. дн.',
            completenessGeneral: 100,
            completenessPredict: 100,
            completenessFact: 100,
            stage: 'Основной период строительства',
            startDeviation: 0,
            endDeviation: 0,
            childTasks: {
                data: [
                    {
                        id: 2,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    durationGeneral: '356 к. дн.',
                                    startDate: '2023-08-21',
                                    endDate: '2024-08-21',
                                    startDateBaseSchedule: '2023-08-21',
                                    endDateBaseSchedule: '2023-08-21',
                                    durationWorkingDays: '356 р. дн.',
                                    durationPredict: '356 р. дн.',
                                    completenessGeneral: 100,
                                    completenessPredict: 100,
                                    completenessFact: 100,
                                    stage: 'Основной период строительства',
                                    startDeviation: 0,
                                    endDeviation: 0,
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        id: 3,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {},
                    },
                    {
                        id: 4,
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {
                            data: [
                                {
                                    id: 5,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    durationGeneral: '356 к. дн.',
                                    startDate: '2023-08-21',
                                    endDate: '2024-08-21',
                                    startDateBaseSchedule: '2023-08-21',
                                    endDateBaseSchedule: '2023-08-21',
                                    durationWorkingDays: '356 р. дн.',
                                    durationPredict: '356 р. дн.',
                                    completenessGeneral: 100,
                                    completenessPredict: 100,
                                    completenessFact: 100,
                                    stage: 'Основной период строительства',
                                    startDeviation: 0,
                                    endDeviation: 0,
                                    childTasks: {
                                        data: [
                                            {
                                                id: 6,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                durationGeneral: '356 к. дн.',
                                                startDate: '2023-08-21',
                                                endDate: '2024-08-21',
                                                startDateBaseSchedule: '2023-08-21',
                                                endDateBaseSchedule: '2023-08-21',
                                                durationWorkingDays: '356 р. дн.',
                                                durationPredict: '356 р. дн.',
                                                completenessGeneral: 100,
                                                completenessPredict: 100,
                                                completenessFact: 100,
                                                stage: 'Основной период строительства',
                                                startDeviation: 0,
                                                endDeviation: 0,
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 7,
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 8,
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 9,
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

export const defaultChart: IPMChart = {
    id: 1,
    calendarType: 5,
    name: '1.3 Проектирование 02А3 Конди Литер 3 стадия П (дом)',
    status: PMChartStatusEnum.stopped,
    stage: 'Stage',
    managerId: 32,
    designerId: 32,
    startedAt: '2024-01-10',
    createdAt: '2024-01-10',
    updatedAt: '2024-01-10',
    projectId: 1,
    type: 1,
};

export const defaultChart2: IPMChart = {
    id: 1,
    calendarType: 5,
    name: '1.4 Проектирование 02А3 Конди Литер 4 стадия П (дом)',
    status: PMChartStatusEnum.stopped,
    stage: 'Stage',
    managerId: 32,
    designerId: 32,
    startedAt: '2024-01-10',
    createdAt: '2024-01-10',
    updatedAt: '2024-01-10',
    projectId: 1,
    type: 1,
};

export const defaultBpces: IPMChartBpc[] = [
    {
        acceptedDate: '2009-08-26T15:23:09.717Z',
        authorId: 32,
        branchDirectorId: 32,
        chartId: 1,
        constructionDirectorDeputyId: 32,
        createdAt: '2009-07-26T15:23:09.717Z',
        generalDirectorDeputyId: 32,
        id: 1,
        isBase: false,
        mainEngineerId: 32,
        name: 'Утвержденная версия №1',
        regionalHeadId: 32,
        status: PMChartBpcStatusEnum.prepared,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 2,
        name: 'Утвержденная версия №2',
        isBase: false,
        acceptedDate: '2010-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 3,
        name: 'Утвержденная версия №3',
        isBase: false,
        acceptedDate: '2011-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 4,
        name: 'Утвержденная версия 4',
        isBase: false,
        acceptedDate: '2012-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
];

export const defaultDpcQuartes: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
    },
];

export const defaultDpcExecs: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
    },
];

export const defaultWeeklyReports: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
        checkerId: 98,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
        checkerId: 32,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
        checkerId: 32,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
        checkerId: 98,
    },
];

export const defaultReportDpcExec: IDictionary[] = [
    { date: '2022-11-01', data: -0.5 },
    { date: '2022-11-08', data: -2.5 },
    { date: '2022-11-15', data: -0.5 },
    { date: '2022-11-22', data: -17.5 },
    { date: '2022-11-29', data: -23.0 },
    { date: '2022-12-06', data: -6 },
    { date: '2022-12-13', data: -0.5 },
    { date: '2022-12-20', data: -0.5 },
    { date: '2022-12-27', data: -0.5 },
    { date: '2023-01-03', data: -15 },
    { date: '2023-01-10', data: -10 },
    { date: '2023-01-17', data: -12 },
    { date: '2023-01-24', data: -0.5 },
    { date: '2023-01-31', data: -0.5 },
    { date: '2023-02-07', data: -0.5 },
    { date: '2023-02-14', data: -0.5 },
    { date: '2023-02-21', data: -0.5 },
    { date: '2023-02-28', data: -0.5 },
    { date: '2023-03-07', data: -5 },
    { date: '2023-03-14', data: -7.5 },
    { date: '2023-03-21', data: -20 },
    { date: '2023-03-28', data: -2.5 },
    { date: '2023-04-04', data: -15 },
    { date: '2023-04-11', data: -0.5 },
    { date: '2023-04-18', data: -0.5 },
    { date: '2023-04-25', data: -0.5 },
    { date: '2023-05-02', data: -2 },
    { date: '2023-05-09', data: -3 },
    { date: '2023-05-16', data: -5 },
    { date: '2023-05-23', data: -2 },
    { date: '2023-05-30', data: -0.5 },
];

export const defaultReportConstruction: IDictionary[] = [
    { date: '2022-11-01', data: 0.5 },
    { date: '2022-11-08', data: 2.5 },
    { date: '2022-11-15', data: 0.5 },
    { date: '2022-11-22', data: 17.5 },
    { date: '2022-11-29', data: 23.0 },
    { date: '2022-12-06', data: 6 },
    { date: '2022-12-13', data: 0.5 },
    { date: '2022-12-20', data: 0.5 },
    { date: '2022-12-27', data: 0.5 },
    { date: '2023-01-03', data: 15 },
    { date: '2023-01-10', data: 10 },
    { date: '2023-01-17', data: 12 },
    { date: '2023-01-24', data: 0.5 },
    { date: '2023-01-31', data: 0.5 },
    { date: '2023-02-07', data: 0.5 },
    { date: '2023-02-14', data: 0.5 },
    { date: '2023-02-21', data: 0.5 },
    { date: '2023-02-28', data: 0.5 },
    { date: '2023-03-07', data: 5 },
    { date: '2023-03-14', data: 7.5 },
    { date: '2023-03-21', data: 20 },
    { date: '2023-03-28', data: 2.5 },
    { date: '2023-04-04', data: 15 },
    { date: '2023-04-11', data: 0.5 },
    { date: '2023-04-18', data: 0.5 },
    { date: '2023-04-25', data: 0.5 },
    { date: '2023-05-02', data: 2 },
    { date: '2023-05-09', data: 3 },
    { date: '2023-05-16', data: 5 },
    { date: '2023-05-23', data: 2 },
    { date: '2023-05-30', data: 0.5 },
];

export const defaultDiagramOptions = {
    chart: {
        type: 'bar',
        toolbar: {
            show: true,
            autoSelected: 'pan',
            export: {
                csv: {
                    filename: 'График отклонения от буфера',
                },
                svg: {
                    filename: 'График отклонения от буфера',
                },
                png: {
                    filename: 'График отклонения от буфера',
                },
            },
            tools: {
                zoomin: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M12 8.70011V12.0334M12 12.0334V15.3668M12 12.0334H15.3333M12 12.0334H8.66667M22 12.0334C22 17.5563 17.5228 22.0334 12 22.0334C6.47715 22.0334 2 17.5563 2 12.0334C2 6.5106 6.47715 2.03345 12 2.03345C17.5228 2.03345 22 6.5106 22 12.0334Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
                zoomout: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M15 12.0334H9M21 12.0334C21 17.004 16.9706 21.0334 12 21.0334C7.02944 21.0334 3 17.004 3 12.0334C3 7.06288 7.02944 3.03345 12 3.03345C16.9706 3.03345 21 7.06288 21 12.0334Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
                zoom: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M21 21.0585L15 15.0417M10 7.01945V10.0278M10 10.0278V13.0362M10 10.0278H13M10 10.0278H7M17 10.0278C17 13.9046 13.866 17.0473 10 17.0473C6.13401 17.0473 3 13.9046 3 10.0278C3 6.15104 6.13401 3.0083 10 3.0083C13.866 3.0083 17 6.15104 17 10.0278Z" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
                pan: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M7 11.5334V14.0334M7 11.5334V5.53345C7 4.70502 7.67157 4.03345 8.5 4.03345C9.32843 4.03345 10 4.70502 10 5.53345M7 11.5334C7 10.705 6.32843 10.0334 5.5 10.0334C4.67157 10.0334 4 10.705 4 11.5334V13.5334C4 17.6756 7.35786 21.0334 11.5 21.0334C15.6421 21.0334 19 17.6756 19 13.5334V8.53345C19 7.70502 18.3284 7.03345 17.5 7.03345C16.6716 7.03345 16 7.70502 16 8.53345M10 5.53345V11.0334M10 5.53345V4.53345C10 3.70502 10.6716 3.03345 11.5 3.03345C12.3284 3.03345 13 3.70502 13 4.53345V5.53345M13 5.53345V11.0334M13 5.53345C13 4.70502 13.6716 4.03345 14.5 4.03345C15.3284 4.03345 16 4.70502 16 5.53345V8.53345M16 8.53345V11.0334" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
                reset: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M0.75 8.12858C0.75 7.72243 0.947332 7.3416 1.27915 7.10737L9.27915 1.46031C9.7113 1.15526 10.2887 1.15526 10.7209 1.46031L18.7209 7.10737C19.0527 7.3416 19.25 7.72243 19.25 8.12858V18.0334C19.25 18.7238 18.6904 19.2834 18 19.2834H13.5C13.3619 19.2834 13.25 19.1715 13.25 19.0334V14.5629C13.25 13.5964 12.4665 12.8129 11.5 12.8129H8.5C7.5335 12.8129 6.75 13.5964 6.75 14.5629V19.0334C6.75 19.1715 6.63807 19.2834 6.5 19.2834H2C1.30964 19.2834 0.75 18.7238 0.75 18.0334V8.12858Z" stroke="#808080" stroke-width="1.5"/></svg>`,
                download: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M4 6.03345H20M4 12.0334H20M4 18.0334H20" stroke="#808080" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
            },
        },
        defaultLocale: 'ru',
        locales: [
            {
                name: 'ru',
                options: {
                    months: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь',
                    ],
                    shortMonths: [
                        'Янв.',
                        'Фев.',
                        'Март',
                        'Апр.',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Авг.',
                        'Сент.',
                        'Окт.',
                        'Нояб.',
                        'Дек.',
                    ],
                    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    toolbar: {
                        exportToSVG: 'Скачать SVG',
                        exportToPNG: 'Скачать PNG',
                        exportToCSV: 'Скачать CSV',
                        selection: 'Выбор',
                        selectionZoom: 'Выбрать Zoom',
                        zoomIn: 'Приблизить',
                        zoomOut: 'Отдалить',
                        pan: 'Смещение',
                        reset: 'Сбросить масштаб',
                        menu: 'Меню',
                    },
                },
            },
        ],
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
            colors: {
                ranges: [
                    {
                        from: -365,
                        to: 0,
                        color: '#eb4a14',
                    },
                    {
                        from: 0,
                        to: 365,
                        color: '#30C07C',
                    },
                ],
            },
            columnWidth: '80%',
        },
    },
    dataLabels: {
        enabled: false,
    },
    yaxis: {
        labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
        },
    },
    xaxis: {
        type: 'datetime',
        categories: [],

        labels: {
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
            },
            datetimeFormatter: {
                month: 'MMMM',
                day: 'dd.MM.yy',
            },
            rotate: -90,
            rotateAlways: true,
            minHeight: 100,
            maxHeight: 100,
        },
        axisBorder: {
            show: true,
            color: '#78909C',
        },
    },
    tooltip: {
        style: {
            fontSize: '14px',
            fontFamily: 'Roboto',
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: true,
            format: 'Дата: dd.MM.yyyy г.',
        },
        y: {
            title: {
                formatter: (seriesName: string) => seriesName + ':',
            },
            formatter: (deviation: number) => {
                if (deviation > 0 && deviation < 1) {
                    return 0;
                }
                return deviation;
            },
        },
        marker: {
            show: false,
        },
    },
    grid: {
        show: true,
        borderColor: '#E5E4E5',
        xaxis: {
            lines: {
                show: true,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
        padding: {
            top: 15,
        },
    },
};

export const defaultReportConsolidatedRows: IDictionary[] = [
    {
        codeSDR: '001',
        name: 'Выполнение основных инженерных изысканий',
        data: ['21.08.2023/\n21.08.2023', '21.08.2023/\n21.08.2023', '100.0'],
    },
    {
        codeSDR: '002',
        name: 'Выполнение основных инженерных изысканий',
        data: ['22.08.2023/\n22.08.2023', '22.08.2023/\n22.08.2023', '100.0'],
    },
    {
        codeSDR: '003',
        name: 'Выполнение основных инженерных изысканий',
        data: ['23.08.2023/\n23.08.2023', '23.08.2023/\n23.08.2023', '100.0'],
    },
    {
        codeSDR: '004',
        name: 'Выполнене основных инженерных изысканий',
        data: ['24.08.2023/\n24.08.2023', '24.08.2023/\n24.08.2023', '100.0'],
    },
    {
        codeSDR: '005',
        name: 'Выполнение основных инженерных изысканий',
        data: ['25.08.2023/\n25.08.2023', '25.08.2023/\n25.08.2023', '100.0'],
    },
];

export const defaultReportConsolidatedRows2: IDictionary[] = [
    {
        codeSDR: '006',
        name: 'Выполнение основных инженерных изысканий',
        data: ['26.08.2023/\n26.08.2023', '26.08.2023/\n26.08.2023', '100.0'],
    },
    {
        codeSDR: '007',
        name: 'Выполнение основных инженерных изысканий',
        data: ['27.08.2023/\n27.08.2023', '27.08.2023/\n27.08.2023', '100.0'],
    },
    {
        codeSDR: '008',
        name: 'Выполнение основных инженерных изысканий',
        data: ['28.08.2023/\n28.08.2023', '28.08.2023/\n28.08.2023', '100.0'],
    },
    {
        codeSDR: '009',
        name: 'Выполнение основных инженерных изысканий',
        data: ['29.08.2023/\n29.08.2023', '29.08.2023/\n29.08.2023', '100.0'],
    },
    {
        codeSDR: '010',
        name: 'Выполнение основных инженерных изысканий',
        data: ['30.08.2023/\n30.08.2023', '30.08.2023/\n30.08.2023', '100.0'],
    },
];

export const defaultReportConsolidatedCharts: IDictionary[] = [
    {
        name: '3.0 Строительство ул. Новая.',
        id: 0,
        rows: defaultReportConsolidatedRows,
    },
    {
        name: '3.1 Строительство ул. Новая.',
        id: 1,
        rows: defaultReportConsolidatedRows2,
    },
    {
        name: '3.2 Строительство ул. Новая.',
        id: 2,
        rows: defaultReportConsolidatedRows,
    },
    {
        name: '3.3 Строительство ул. Новая.',
        id: 3,
        rows: defaultReportConsolidatedRows2,
    },
    {
        name: '3.4 Строительство ул. Новая.',
        id: 4,
        rows: defaultReportConsolidatedRows,
    },
];

export const defaultCities: IPMCity[] = [
    {
        id: 1,
        name: 'Уфа',
        projects: [
            {
                id: 1,
                name: '02А0 Конди Литер 3',
                code: '02А0',
                status: 1,
                fileUrl: '',
                chartTypes: [
                    {
                        id: 1,
                        name: 'Сводный',
                        charts: [
                            {
                                id: 1,
                                status: 1,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Сводный Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                            {
                                id: 2,
                                status: 2,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Сводный Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 2,
                        name: 'Проектирование',
                        charts: [
                            {
                                id: 3,
                                status: 3,
                                stage: 'Основные ИИ, Стадия П, Экспертиза РС',
                                name: '02А0 Проектирование Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 3,
                        name: 'Строительство',
                        charts: [
                            {
                                id: 4,
                                status: 4,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Строительство Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 4,
                        name: 'Тендеры',
                        charts: [
                            {
                                id: 5,
                                status: 1,
                                stage: 'Основные ИИ, Стадия П, Экспертиза РС',
                                name: '02А0 Тендеры Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const defaultProjects: IDictionary[] = [
    {
        id: 1,
        name: '02А0 Конди Литер 3',
        code: '02А0',
        statuses: {
            summary: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            designing: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            construction: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            tenders: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
        },
    },
];
